<template>
    <draggable tag="ul" :group="{ name: 'cibles' }" :list="cibles">
    <li v-for="(cible, index) in ciblesWithLinkedObj" :key="cible.id">
      <div class="row">
        <q-checkbox v-if="isAdmin" :value="cibles[index].selected" @input="(value) => $emit('change', { id: cible.id, key: 'selected', value })" color="per" class="col col-shrink" />
        <div class="col col-11">
          <base-card v-bind="cible" draggable @menuClick="onMenuClick">
            <q-avatar slot='avatar' size="3rem" color="per" icon="icon-icons_souscible" />

            <q-item class="items-start q-pa-none">
              <q-item-section v-if="cible">
                <q-item-label v-if="cible.isEditingDate" caption>
                  <base-input-date :label="$t('cibles.edit_date')" color="per" dense
                    v-model="cible.date" ref="date_realisation_all" @input="$emit('submit', { id: cible.id, key: 'date', value: cible.date })" />
                </q-item-label>
                <q-item-label v-else caption>
                  {{$formatDate(cible.date)}}
                  <q-btn v-if="isAdmin" flat dense size="sm" icon="edit" @click="() => $emit('change', { id: cible.id, key: 'isEditingDate', value: true })" />
                </q-item-label>
                <q-item-label>
                  <strong><router-link :to="{ name: 'sous-cible-show', params: { id: cible.id } }">{{$t(`sousCibles.fields.type.options.${cible.type}`)}}</router-link></strong>
                </q-item-label>

                <div class="row">
                  <div class="col" v-for="(obj, indexLinkedObject) in ciblesWithLinkedObj[index].linkedObjects" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar :icon="obj.icon.name" size="2rem" :color="obj.icon.color" />
                      </q-item-section>
                      <q-item-section>
                        <div class="row items-center">
                          <div v-if="obj.isEditingLabel">
                            <div v-if="obj.type === 'agents'">
                              <base-input-text label="users.fields.prenom.label" class="q-pb-none" v-model="ciblesWithLinkedObj[index].linkedObjects[indexLinkedObject].PRENOM" color="per"
                                ref="label" />
                              <base-input-text label="users.fields.nom.label" class="q-pb-none" v-model="ciblesWithLinkedObj[index].linkedObjects[indexLinkedObject].NOMITV" color="per"
                                ref="label" />
                            </div>
                            <div v-if="obj.type === 'wallets'">
                              <base-input-text label="users.fields.portefeuille.label" v-model="ciblesWithLinkedObj[index].linkedObjects[indexLinkedObject].NOMPTF" color="per"
                                ref="label" />
                            </div>
                            <div v-if="obj.type === 'entities'">
                              <base-input-text label="users.fields.entite.label" v-model="ciblesWithLinkedObj[index].linkedObjects[indexLinkedObject].label" color="per"
                                ref="label" />
                            </div>
                          </div>
                          <q-item-label v-else class="col col-auto" caption>{{obj.label}}</q-item-label>

                          <div v-if="obj.isEditingLabel" class="col col-auto q-ml-sm">
                            <q-btn flat dense size="sm" icon="check" @click="() => submitLabel(obj)" />
                            <q-btn flat dense size="sm" icon="cancel" @click="() => $emit('changeLinkedObject', { id: obj.id, type: obj.type, key: 'isEditingLabel', value: false })" />
                          </div>
                          <q-btn class="col col-auto q-ml-sm" v-else-if="isAdmin" flat dense size="sm" icon="edit" @click="() => $emit('changeLinkedObject', { id: obj.id, type: obj.type, key: 'isEditingLabel', value: true })" />
                        </div>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </q-item-section>
            </q-item>

            <div slot="indicators" v-if="formFields">
              <div class="q-py-md column justify-between">
                <base-input-select
                  v-bind="formInputProps('statut')"
                  :options="formInputProps('statut').options.map(option => ({ label: `sousCibles.fields.statut.options.${option.label}`, value: option.value }))"
                  @input="$emit('submit', { id: cible.id, key: 'statut', value: cible.statut.value })"
                  dense color="per" class="row q-pb-none"
                  v-model="cible.statut" ref="statut" :readonly="!isAdmin">
                  <template #selected="{ props }">
                    <q-icon size="1rem" :name="$getIcon('statut', props)" class="q-pb-sm" />
                  </template>
                </base-input-select>
                <base-input-select v-bind="formInputProps('qualification')" :readonly="!isAdmin" dense color="per" class="row q-pb-none"
                  @input="$emit('submit', { id: cible.id, key: 'qualification', value: cible.qualification.value })"
                  v-model="cible.qualification" ref="qualification">
                  <template #selected="{ props }">
                    <q-icon size="1rem" :name="$getIcon('qualification', props)" class="q-pb-sm" />
                  </template>
                </base-input-select>
              </div>
            </div>
          </base-card>
        </div>
      </div>
      <div class="row">
        <SousCiblesHierarchy :cibles="cible.children" class="col"
          @submitLabelLinkedObject="(payload) => $emit('submitLabelLinkedObject', payload)"
          @changeLinkedObject="(payload) => $emit('changeLinkedObject', payload)"
          @submit="(payload) => $emit('submit', payload)"
          @change="(payload) => $emit('change', payload)"/>
      </div>
    </li>
  </draggable>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import { BaseForm } from '../../mixins'

export default {
  name: 'SousCiblesHierarchy',
  components: { draggable },
  mixins: [BaseForm],

  data () {
    return {
      ciblesWithLinkedObj: []
    }
  },

  props: {
    'cibles': { type: Array },
    'form_model': { type: String, default: 'sousCibles' },
    'action': { type: String, default: '' }
  },

  computed: {
    ...mapGetters({
      favorites: 'favorites/getFavorites',
      isAdmin: 'auth/isAdmin',
      formFields: 'sousCibles/getFormFields'
    })
  },

  watch: {
    cibles: {
      handler (newVal) {
        if (!newVal) return
        this.ciblesWithLinkedObj = newVal.map(cible => {
          const related_objects = []
            .concat(cible.agents)
            .concat(cible.wallets)
            .concat(cible.entities)

          let actions = cible.actions && cible.actions.length > 0
            ? cible.actions
            : [{ label: this.$t('sousCibles.edit_sousCible'), payload: { name: 'sous-cible-show', params: { id: cible.id } } }]

          return {
            ...cible,
            actions: actions,
            is_favorite: !!this.favorites && !!this.favorites['sousCible'] &&
              this.favorites['sousCible'].some(favorite => favorite.id === cible.id),
            model: { model_type: 'cible', model_id: cible.id },
            linkedObjects: related_objects.map(obj => ({
              ...obj,
              type: this.linkedObjectType(obj),
              label: this.linkedObjectLabel(obj),
              icon: this.linkedObjectIcon(obj)
            }))
          }
        })
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    submitLabel (obj) {
      if (obj.type === 'agents') {
        this.$emit('submitLabelLinkedObject', { id: obj.id, type: 'agents', keysValues: { PRENOM: obj.PRENOM, NOMITV: obj.NOMITV } })
      } else if (obj.type === 'wallets') {
        this.$emit('submitLabelLinkedObject', { id: obj.id, type: 'wallets', key: 'NOMPTF', value: obj.NOMPTF })
      } else if (obj.type === 'entities') {
        this.$emit('submitLabelLinkedObject', { id: obj.id, type: 'entities', key: 'label', value: obj.label })
      }
    },
    onMenuClick ({ name, params }) {
      if (name === 'model-detach') {
        this.$emit('menuClick', { name, params })
      } else this.$router.push({ name, params })
    },

    linkedObjectType (obj) {
      if ('NOMITV' in obj) { return 'agents' }
      if ('CODE_RGT' in obj) { return 'wallets' }
      if ('CODEICX' in obj) { return 'entities' }
      return undefined
    },

    linkedObjectLabel (obj) {
      if (this.linkedObjectType(obj) === 'agents') { return `${obj['PRENOM']} ${obj['NOMITV']}` }
      if (this.linkedObjectType(obj) === 'wallets') { return obj['NOMPTF'] }
      if (this.linkedObjectType(obj) === 'entities') { return obj['label'] }
      return undefined
    },

    linkedObjectIcon (obj) {
      if (this.linkedObjectType(obj) === 'agents') { return { name: 'accessibility', color: 'reseau' } }
      if (this.linkedObjectType(obj) === 'wallets') { return { name: 'account_balance_wallet', color: 'reseau' } }
      if (this.linkedObjectType(obj) === 'entities') { return { name: 'account_balance_wallet', color: 'reseau' } }
      return undefined
    }
  }
}
</script>

<style lang="stylus" scoped>
ul
  list-style: none
  padding: 0
  ul
    padding: 0.5rem 0 0 1.5rem
</style>
