<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <base-input-text v-bind="formInputProps('admin_note')" color="per"
        v-model="formData.admin_note" ref="admin_note" />

      <q-card flat bordered class="q-pa-sm">
        <q-card-section class="q-gutter-md">
          <base-input-text v-bind="formInputProps('label')" color="per"
            v-model="formData.label" ref="label" />

          <base-input-text v-bind="formInputProps('description')" color="per"
            v-model="formData.description" ref="description" />
        </q-card-section>
      </q-card>

      <!-- <div class="row q-gutter-md">
        <div class="col col-6">
          <h6>{{$t('cibles.fields.model.label')}}</h6>
          <component v-if="linkedObjectComponent && linkedObj" v-bind:is="linkedObjectComponent" v-bind:model="linkedObj"
            :menuOptions="linkedObjMenu" @menuClick="onLinkedObjMenuClick" />

          <search-box v-else v-model="linkedObj"
            :label="$t('tasks.fields.model.label')"
            :color="contentColor" />
        </div>
      </div> -->

      <div v-if="$route.params.id">
        <h6 class="title-h6">{{$t('sousCibles.sousCibles')}}</h6>
        <base-add-button v-if="isAdmin" color="per" :label="$t('sousCibles.add_sousCible')"
          :to="{ name: 'sous-cible-create', params: { groupe_cible: cible } }" />

        <div class="row q-pt-md">
          <div class="col col-3">
            <q-checkbox v-if="isAdmin" v-model="selectAllValue" @input="toggleAllSousCibles" color="per" :label="$t('cibles.select_all')" />
          </div>
          <div class="col col-3" v-if="isAnySousCibleSelected">
            <base-input-date :label="$t('cibles.edit_date')" color="per" dense
              v-model="dateVisee" ref="date_realisation_all" />
          </div>
        </div>
        <SousCiblesHierarchy :cibles="sous_cibles" @submit="submitSousCible" @submitLabelLinkedObject="submitLabelLinkedObject" @change="updateCible" @changeLinkedObject="updateLinkedObject" />
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none" v-if="formInputProps('date')">
          <base-input-date v-bind="formInputProps('date')" color="per"
            v-model="formData.date" ref="date" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('date_validation')">
          <base-input-date v-bind="formInputProps('date_validation')" color="per"
            v-model="formData.date_validation" ref="date_validation" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('date_realisation')">
          <base-input-date v-bind="formInputProps('date_realisation')" color="per"
            v-model="formData.date_realisation" ref="date_realisation" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('statut')">
          <base-input-select v-bind="formInputProps('statut')" color="per"
            v-model="formData.statut" ref="statut" />
        </q-item>

        <q-item class="q-pa-none" v-if="formInputProps('qualification')">
          <base-input-select v-bind="formInputProps('qualification')" color="per"
            v-model="formData.qualification" ref="qualification" />
        </q-item>

        <q-item class="column items-start q-pa-none" v-if="formInputProps('switch_comptage') || formInputProps('switch_meteo')">
          {{$t('cibles.integrer_stats_PER')}}
          <base-input-boolean
            v-model="formData.switch_comptage"
            v-bind="formInputProps('switch_comptage')"
            color="per"
            class="row"
            v-if="formInputProps('switch_comptage')"
          />

          <base-input-boolean
            v-model="formData.switch_meteo"
            v-bind="formInputProps('switch_meteo')"
            color="per"
            v-if="formInputProps('switch_meteo')"
          />
        </q-item>
        <q-separator />
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn color="per full-width" size="sm" unelevated type="submit" :disabled="!hasChanged || !isAdmin">{{$t('form.submit')}}</q-btn>
        <q-btn color="per full-width" size="sm" outline @click="$router.go(-1)">{{$t('form.cancel')}}</q-btn>
        <q-btn color="negative full-width" size="sm" unelevated icon-right="delete"
          v-if="$route.params.id" @click.native="destroy" :disabled="!isAdmin">
          {{$t('cibles.delete_cible')}}
        </q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../mixins'
import { SousCiblesHierarchy } from '../../components/sous-cibles'

export default {
  name: 'CibleDetails',
  mixins: [BaseForm],
  props: {
    'form_model': { type: String, default: 'cibles' },
    'action': { type: String, default: 'cibles/saveCible' }
  },
  components: { SousCiblesHierarchy },

  data () {
    return {
      sous_cibles: [],
      selectAllValue: false,
      dateVisee: null
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      cible: 'cibles/getCurrentCible'
    }),

    isAnySousCibleSelected () {
      return this.getAllSousCibleWithChildren().find(sousCible => sousCible.selected)
    },

    label () {
      return !this.$route.params.id ? this.$t('cibles.add_cible') : this.$t('cibles.edit_cible')
    },

    isAllSousCiblesSelected () {
      return !!this.sous_cibles.find(sousCibles => !sousCibles.selected)
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        if (!newVal || !this.cible) return

        this.$store.dispatch('sousCibles/fetchFormFields')

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.cible[id]) : this.cible[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
        }

        function mapSousCible (sousCible) {
          // Mappena le reniny
          const mappedSousCible = {
            ...sousCible,
            selected: false,
            isEditingDate: false,
            agents: sousCible.agents.map(agent => ({ ...agent, isEditingLabel: false })),
            wallets: sousCible.wallets.map(wallet => ({ ...wallet, isEditingLabel: false })),
            entities: sousCible.entities.map(entity => ({ ...entity, isEditingLabel: false })),
            children: sousCible.children && sousCible.children.length ? sousCible.children.map(sousCible => mapSousCible(sousCible)) : []
          }

          return mappedSousCible
        }
        this.sous_cibles = this.cible.sous_cibles.map(sousCible => mapSousCible(sousCible))
      },
      immediate: true
    },

    sous_cibles: {
      handler (newVal, oldVal) {
        const pickCibles = val => val.children && val.children.length ? { id: val.id, children: val.children.map(pickCibles) } : { id: val.id }
        this.formData.cibles_hierarchy = JSON.stringify(newVal.map(cible => pickCibles(cible)))
        // console.log(this.formData.cibles_hierarchy)
        if (oldVal) this.hasChanged = true
      },
      deep: true
    },

    dateVisee: {
      handler (newVal) {
        this.submitSousCiblesDateVisée()
      }
    }
  },

  mounted () {
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  methods: {
    submitSousCible ({ id, key, value }) {
      this.$store.dispatch('sousCibles/saveSousCible', {
        id,
        [key]: value
      }).then(() => {
        this.updateCible({ id, key, value })
        this.updateCible({ id, key: 'isEditingDate', value: false })
        this.notifySuccess('cibles.save_success')
      })
    },
    submitLabelLinkedObject ({ id, type, key, value, keysValues }) {
      this.$store.dispatch('sousCibles/saveLinkedObject', {
        id,
        type,
        ...keysValues,
        [key]: value
      }).then(() => {
        this.updateLinkedObject({ id, type, key, value })
        if (keysValues) {
          Object.keys(keysValues).forEach(_key => {
            this.updateLinkedObject({ id, type, key: _key, value: keysValues[_key] })
          })
        }

        this.updateLinkedObject({ id, type, key: 'isEditingLabel', value: false })
        this.notifySuccess('cibles.save_success')
      })
    },
    getAllSousCibleWithChildren () {
      const getSousCibleWithChildren = (acc, sousCible) => {
        acc.push(sousCible)

        sousCible.children.forEach(childSousCible => {
          acc.concat(getSousCibleWithChildren(acc, childSousCible))
        })

        return acc
      }

      return this.sous_cibles.reduce((acc, sousCible) => {
        acc.concat(getSousCibleWithChildren(acc, sousCible))
        return acc
      }, [])
    },
    submitSousCiblesDateVisée () {
      let selectedSousCibleId = this.getAllSousCibleWithChildren().filter(sousCible => sousCible.selected).map(sousCible => sousCible.id)
      selectedSousCibleId.forEach(id => {
        this.$store.dispatch('sousCibles/saveSousCible', {
          id,
          date: this.dateVisee
        }).then(() => {
          this.updateCible({ id, key: 'date', value: this.dateVisee })
          this.notifySuccess('cibles.save_success')
        })
      })
    },
    findSousCible (id, currentSousCible) {
      if (id === currentSousCible.id) return currentSousCible
      if (!currentSousCible.children.length) return null

      let result = null

      for (const index in currentSousCible.children) {
        let sousCible = currentSousCible.children[index]

        if (sousCible.id === id) return sousCible
        result = this.findSousCible(id, sousCible)
        if (result) return result
      }

      return result
    },
    updateCible ({ id, key, value }) {
      let sousCible = null
      for (const index in this.sous_cibles) {
        const sousCibleTemp = this.findSousCible(id, this.sous_cibles[index])
        if (sousCibleTemp) {
          sousCible = sousCibleTemp
          break
        }
      }

      if (sousCible) sousCible[key] = value
    },
    updateLinkedObject ({ id, type, key, value }) {
      this.getAllSousCibleWithChildren().forEach(sousCible => {
        let linkedObject = sousCible[type].find(_linked => _linked.id === id)
        if (linkedObject) linkedObject[key] = value
      })
    },

    toggleAllSousCibles (value) {
      this.getAllSousCibleWithChildren().forEach(sousCible => { sousCible.selected = value })
    },
    onSave ({ id }) {
      if (this.$route.params.id) {
        this.$router.push({ name: 'cibles-list' })
      // if (this.$route.params.id) {
      //   this.$store.dispatch('sousCibles/getSousCible', this.$route.params.id)
      } else {
        this.$router.push({ name: 'cible-show', params: { id } })
      }
    },

    destroy () {
      this.$confirm(this.$t('cibles.delete_cible_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('cibles/deleteCible', this.$route.params.id).then(() => {
          this.notifySuccess('cibles.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
<!--  -->
